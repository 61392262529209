
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {FormToadd} from './component/Form';
import React from 'react';
import { GenPdfDoc } from './component/GenPDFDoc/GenPDFDoc';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  } from "react-router-dom";


function App() {


  return (

    <div >
          <Router>
             <Routes>
                <Route path="/" element={<FormToadd />} />
                <Route path="/pdfLoad" element={<GenPdfDoc />} />
            </Routes>
          </Router>
    </div>
  );
}

export default App;
