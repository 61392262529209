// import { SelectMachine } from './component/Modal';
import { useContext, useEffect, useState } from "react"
import {Navigate,Link} from "react-router-dom";
import { ProductsContext } from "../context/Product.context";
import SelectMachine from "./MachinSelect";
import {ImageNameArr} from "../Data/image_name";
import  {MachineSelectContext} from "../context/MachineSelect";
import CheckoutItem from "./MachineTable/MachineTable";






export const FormToadd = ( )=> {
console.log("Form Load WORKING")

const {products,setProducts} = useContext(ProductsContext);
const {machine,machineImage,isHidden,setHidden}=useContext( MachineSelectContext );

const [WgSclaeValues,setWgScale] = useState({

    Quation_Ref_No:"",
    Date:"",
    Party_Name:"",
    Company_Name:"",
    Enquiry_No:"",

})


const onChangeHandler = (e)=>{

    const{name,value} =e.target;
    setWgScale({...WgSclaeValues,[name]:value});
}

// useEffect(()=>{

//     return () => console.log(WgSclaeValues);
      
// },[])

const onSubmitHandler = ()=>{
        console.log("on submit")
   setProducts({WgSclaeValues,machine});
}


const {  Quation_Ref_No,Date, Party_Name, Company_Name, Enquiry_No,} = WgSclaeValues;
console.log(products);

return(
            <div className="row g-3" >
                <div className="col-md-6">
                <label  className="form-label">Quation Ref No.</label>
                <input value={Quation_Ref_No} name="Quation_Ref_No" onChange={onChangeHandler} type="text" className="form-control" id="inputEmail4" />
                </div>
                <div className="col-md-6">
                <label  className="form-label">Date</label>
                <input value={Date} name="Date" onChange={onChangeHandler} type="date" className="form-control" id="inputPassword4" />
                </div>
                <div className="col-12">
                <label  className="form-label">Party Name</label>
                <input value={Party_Name} name="Party_Name" onChange={onChangeHandler} type="text" className="form-control" id="inputAddress" placeholder=" AJit KAUSHIK" />
                <div style={{marginTop:"10px"}}>
                <input  value={Company_Name} name="Company_Name" onChange={onChangeHandler} type="text" className="form-control" id="inputAddress" placeholder=" MicroHard Tech" />
                </div>
                </div>
                <div className="col-12">
                <label  className="form-label">Enquiry No.</label>
                <input value={Enquiry_No} name="Enquiry_No" onChange={onChangeHandler} type="text" className="form-control" id="inputAddress2" placeholder="Pr: No- 1234 if Given.." />
                </div>
                <div className="col-md-6">
                <button type="button"  className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Add Weighing Scale
                    </button>
                </div>
               
               
                <div className="col-12">
                <div className="form-check">
                    {/* <input className="form-check-input" type="checkbox" id="gridCheck" /> */}
                  
                    {machine.map((cur,ind)=><CheckoutItem cartItem={cur} key={ind} />)} 
                    {/* <CheckoutItem /> */}
                    {/* <label className="form-check-label" >
                    Check me out
                    </label> */}
                </div>
                </div>
                <div className="col-12">
                <Link onClick={onSubmitHandler} className="btn btn-success" to="/pdfLoad">
                    Genrate Quation
                </Link>
                </div>
                  
                {/* <img src={machine.]} /> */}
                 <SelectMachine  />
            </div>
)

}

