import { createContext, useState ,children } from "react";

export const ProductsContext = createContext({

    products:[],
    setProducts:()=>{}

});






export const ProductsProvider = ({children}) =>{

    const[products,setProducts] = useState([]);
    const value = {products,setProducts};
    // console.log("Context is Working")
    console.log(products)

    return(
        <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>
    )
};

