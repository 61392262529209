import { ProductsContext } from "../../context/Product.context";
import React, { useContext, useEffect, useState } from "react";
import Pdf from "react-to-pdf";
import  "./GenPDFdoc.css"
import IsoLogo from "../../assets/Iso.png"
import MHLogo from "../../assets/microh.PNG";
import TableofMachine from "./TableofMachine";

const ref = React.createRef();


export const GenPdfDoc =(  )=>{

  const [EnquiryDetails,setEnqDetails] = useState({

    Quation_Ref_No:"",
    Date:"",
    Party_Name:"",
    Company_Name:"",
    Enquiry_No:"",

})

const {products} = useContext(ProductsContext);
const {WgSclaeValues,machine}=products

useEffect(()=>{
setEnqDetails(WgSclaeValues);
},[])

const options = {
  orientation: 'landscape',
  unit: 'in',
  format: [12,10]
};
const {  Quation_Ref_No,Date, Party_Name, Company_Name, Enquiry_No} = EnquiryDetails;


return(
    <div className="pdfDoc">
        <div  className="pdf_part" ref={ref}>
      <header className="header">
        {/* <div className="logo_container" > */}
          <img className="iso_img" src={IsoLogo}  height="150px" width="250px"/>
        
         <div className="mh_details">
          <img className="MH_Logo" src={MHLogo}  height="100px" width="350px"/>
          <div className="units_details">
              <h2>UNIT (I) :- MICROHARD TECHNOLOGICS</h2>
              <p>Behind Ishan Tryambakeshwar Temple,
                  Near Boni Baby Hospital ,Banswara 327001(raj.)</p>
              <h2>UNIT (II) :- MICROHARD TECHNOLOGICS</h2>
              <p>D123A,Kamla Bhavan, Kailash Marg, Banipark
                  Jaipur 302016 (Raj.) India</p>
              <h2>UNIT (III) :-AJIT KAUSHIK SCALE and COMPANY</h2>
              <p>House no. 20,behind sajan sajni vatika,
                  Banswara  327001(raj.)</p>
              <h3>Mo.:-9414101119 ,8949613375</h3>
              <h3>E-mail :- mhtajit@gmail.com</h3>
              </div>
            </div> 
        {/* </div> */}
            
      </header>


  <div className="qu_Prtydtls">
      <div className="qu_ref_date">
         <h3> Quote. Ref. - {Quation_Ref_No}</h3>
        <h3 > Date - {Date}</h3>
       </div>

        <h3>To,</h3>
        <h3>Name {Party_Name}</h3>
        <h3>Company_Name {Company_Name}</h3>
  </div>


  <div className="enquiry_details">
    <h3>Dear Sir,</h3>
    <p>We thank you for an Enquiry { Enquiry_No && `No.- ${Enquiry_No}`} and subsequent discussions
        with you. We are pleased to quote you our most competitive rates</p>
      </div>
        
        
        <div className="model_details_tabel">
            <TableofMachine MachineItem={machine} />
        </div>
        
      <div className="gst_dtls">
         <h3 ><u> Commercial Terms:</u></h3>
          <h4>  GST:18% Extra </h4>
          <h4>Delivery :Within two week from the date of receipt of P.O.</h4>
          <h4>Payment :100 % Advance</h4>
          <h4>Warranty :1 Year</h4>
          <h4>Stamping Charges:500/-</h4>
          </div>
      <div className="signtr">
         <h6> Thanking and assuring you of our best services at all times.</h6>
          <h5>Regards,</h5>
          <h5>Director</h5>
          <h4>Mr. Ajit Kaushik</h4>
         <h4> Microhard Technologics</h4>
         </div>
        
        </div>


  <div className="pdfBtn">
        <Pdf targetRef={ref} filename="code-example.pdf" x={.1} y={.5} scale={0.8} options={options}>
        {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
       </Pdf>
      </div >
    
    
    </div>
)



}