import { createContext, useState } from "react";
import {ImageNameArr} from "../Data/image_name"


const addMachine = (machine, machineToAdd) => {
    const existingModel = machine.find(
      (mchModl) => mchModl.id === machineToAdd.id
    );                      
                 if (existingModel) {
      return machine.map((mchModl) =>
      mchModl.id === machineToAdd.id
          ? { ...machineToAdd }
          : mchModl
      );
    }
  
    return [...machine, { ...machineToAdd , id:machine.length}];
  };
  

const editMachine =(machine, machineToedit)=>{

const existingModel = machine.find(
      (mch) => mch.id === machineToedit.id);                      
               
      if (existingModel) {
      return machine.map((mch) =>
        mch.id === machineToedit.id
          ? { ...machineToedit }
          : mch
      );
    }

    return [...machine, { ...machineToedit }];

}


  const removeMachine = (machine, machineToRemove) => {
    // find the cart item to remove
    const existingMachine = machine.find(
      (mch) => mch.id === machineToRemove.id
    );
  
    // check if quantity is equal to 1, if it is remove that item from the cart
    if (existingMachine.quantity === 1) {
      return machine.filter((mch) => mch.id !== machineToRemove.id);
    }
  
    // return back cartitems with matching cart item with reduced quantity
    return machine.map((mch) =>
      mch.id === machineToRemove.id
        ? { ...machine, quantity: mch.quantity - 1 }
        : machine
    );
  };




  const clearMachine = (machine, MachineToClear) =>
  machine.filter((mch) => mch.id !== MachineToClear.id);


















export const MachineSelectContext = createContext({

    machine:[],
    setMachine:()=>{},
    products:[],
    setProducts:()=>{},
    machineImage:[],
    isHidden:true,
    setHidden:()=>{},
    addMachineToCart: () => {},
    removeMachineFromCart: () => {},
    clearMachineFromCart: () => {},
    editMachinedetails:()=>{},
    machineValues:[],
    setMchValues:()=>{},
    selectedOptionImg:[], setSelectImg:()=>{},
  });






export const MachineSelectProvider = ({children}) =>{
    const[machineImage] = useState(ImageNameArr);
    const[machine,setMachine] = useState([]);
    const [isHidden,setHidden] = useState(true);
    const [machineValues,setMchValues] = useState({
      modelName:"",
      Capacity:"",
      Accuracy:"",
      Pan_Size:"",
      Quantity:"", 
      Price:""
})
const  [selectedOptionImg, setSelectImg] = useState([]);


  const  addMachineToCart= (MachineToAdd) => {
    setMachine(addMachine(machine, MachineToAdd));
  };

  const  removeMachineFromCart= (MachineToRemove) => {
    setMachine(removeMachine(machine, MachineToRemove));

  };
  const  clearMachineFromCart= (MachineClear) => {
    setMachine(clearMachine(machine, MachineClear));

  };

  const editMachinedetails = (MachineToedit)=>{
    const {ImgDetails } = MachineToedit;
    setMchValues(MachineToedit);
    setSelectImg(ImgDetails);
    console.log("eidt working",ImgDetails)
  }



    const value = {
        machine,
        setMachine,
        machineImage,
        addMachineToCart,
        editMachinedetails,
        removeMachineFromCart,
        clearMachineFromCart,machineValues,setMchValues,selectedOptionImg, setSelectImg};
    
    console.log("Machine_Context is Working")
    console.log("fromContext",machine)

    return(
        <MachineSelectContext.Provider value={value}>{children}</MachineSelectContext.Provider>
    )
};

