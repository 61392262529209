  import Pans_GPP from "../assets/12_Pans_GPP.png" ;
  import CS_MS from "../assets/CS_MS.png" ;
  import CS_SS from "../assets/CS_SS.png" ;
  import Jw862_Jw863_Jw864 from "../assets/Jw862_Jw863_Jw864.png" ;
  import JW865_JW866_JW867_JW1190 from "../assets/JW865_JW866_JW867_JW1190.png" ;
//   import Sansui_Logo from "../assets/SansuiLogo.png" ;
  import Sansui from "../assets/Sansui.png" ;
  import SBMI_UHW02 from "../assets/SBMI_UHW02.png" ;
  import SMW_KL_MS from "../assets/SMW_KL_MS.png" ;
  import SPP_2T4_1x2M from "../assets/SPP_2T4 _1x2M.png" ;
  import SPP_2T4_18M from "../assets/SPP_2T4_1.8M.png" ;
  import SPP_6T4_20_M from "../assets/SPP_6T4_2.0_M.png" ;
  import SPP_300_BS from "../assets/SPP_300_BS.png" ;
  import SPP_500_1_M from "../assets/SPP_500_1_M.png" ;
  import SPP_ABS from "../assets/SPP_ABS.png" ;
  import SPP_ABS_S from "../assets/SPP_ABS_S.png" ;
  import SPP_BENCH from "../assets/SPP_BENCH.png" ;
  import SPP_BL_BC_BPS from "../assets/SPP_BL_BC_BPS.png" ;
  import SPP_BL_L from "../assets/SPP_BL_L.png" ;
  import SPP_BL_LPW_BP from "../assets/SPP_BL_LPW_BP.png" ;
  import SPP_BL_LPW_BX from "../assets/SPP_BL_LPW_BX.png" ;
  import SPP_BL_M_CH_PL from "../assets/SPP_BL_M_CH_PL.png" ;
  import SPP_BL_S from "../assets/SPP_BL_S.png" ;
  import SPP_BL_TROLLY from "../assets/SPP_BL_TROLLY.png" ;
  import SPP_MINI_CH_PL from "../assets/SPP_MINI_CH.PL.png" ;
  import SPP_MINI_SS from "../assets/SPP_MINI_SS.png" ;
  import SPP_PR_BL_Ch_PAN from "../assets/SPP_PR_BL_Ch.PAN.png" ;
  import SPP_PR_BL_SS_PAN from "../assets/SPP_PR_BL_SS_PAN.png" ;
  import SPP_SS from "../assets/SPP_SS.png" ;
  import SPP_T_ECO_12M from "../assets/SPP_T ECO_1.2M.png" ;
  import SPP_T_BL_LPW_XP from "../assets/SPP_T_BL_LPW_XP.png" ;
  import SSP_1_EXL_FR from "../assets/SSP_1_EXL_FR.png" ;
  import SSP__EXL_P from "../assets/SSP__EXL_P.png" ;
  import SSP__DLX_P from "../assets/SSP__DLX_P.png" ;
  import SRP_SS from "../assets/SRP_SS.png" ;
  import SRP_GR_P from "../assets/SRP_GR_P.png" ;
  import SRP_DLX_P from "../assets/SRP_DLX_P.png" ;
  import SRP_BL_S from "../assets/SRP_BL_S.png" ;
  import SRP_BL from "../assets/SRP_BL.png" ;
  import SRC_BL_S from "../assets/SRC_BL_S.png" ;
  import SRC_BL_M from "../assets/SRC_BL_M.png" ;
  import SRC_BL from "../assets/SRC_BL.png" ;
  import SSP_BL from "../assets/SSP_BL.png" ;
  import SSP_BL_BC_TTW from "../assets/SSP_BL_BC_TTW.png" ;
  import SSP_BL_LPW_TT from "../assets/SSP_BL_LPW_TT.png" ;
  import SSP_DLX_FR from "../assets/SSP_DLX_FR.png" ;
  import SSP_EXL_Baby from "../assets/SSP_EXL_(Baby).png" ;
  import SSP_FB from "../assets/SSP_FB.png" ;
  import SSP_GR from "../assets/SSP_GR.png" ;
  import SSP6BLJW from "../assets/SSP6BLJW_SSP10BLJW_SSP20BLJW_SSP30BLJW_SSP30 BLJW_SSP34BLJw_SSP3BLJW.png" ;
  import TT71_SSP_5_TT721_SSP_10_TT722_SSP_20_TT723_SSP820 from "../assets/TT71_SSP_5_TT721_SSP_10_TT722_SSP_20_TT723_SSP820.png" ;
  import TT723_SSP from "../assets/TT723_SSP 82.png" ;
  import Weighbridge_Indicator from "../assets/Weighbridge_Indicator.png" 
    
    
  export let ImageNameArr = [
    {   id:"01",
        label:"Pans_GPP",
        Image:Pans_GPP
    },
    {   id:"02",
        label:"CS_MS",
        Image:CS_MS
    } ,
    {   id:"03",
    label:"CS_SS",
    Image:CS_SS
    },
    {   id:"04",
    label:"Jw862_Jw863_Jw864",
    Image:Jw862_Jw863_Jw864
    } ,
    {   id:"05",
    label:"JW865_JW866_JW867_JW1190",
    Image:JW865_JW866_JW867_JW1190
    } ,
    {   id:"06",
    label:"Sansui",
    Image:Sansui
    }  ,
    {   id:"07",
    label:"SBMI_UHW02",
    Image:SBMI_UHW02
    }  ,
    {   id:"08",
    label:"SMW_KL_MS",
    Image:SMW_KL_MS
    },
    {   id:"09",
    label:"SPP_2T4_1x2M",
    Image:SPP_2T4_1x2M
    } ,
    {   id:"10",
    label:"SPP_6T4_20_M",
    Image:SPP_6T4_20_M
    } ,
    {   id:"11",
    label:"SPP_300_BS",
    Image:SPP_300_BS
    } ,
    {   id:"12",
    label:"SPP_500_1_M",
    Image:SPP_500_1_M
    } ,
    {   id:"13",
    label:"SPP_ABS",
    Image:SPP_ABS
    }  ,
    {   id:"14",
    label:"SPP_ABS_S",
    Image:SPP_ABS_S
    }  ,
    {   id:"15",
    label:"SPP_BENCH",
    Image:SPP_BENCH
    } ,
    {   id:"16",
    label:"SPP_BL_BC_BPS",
    Image:SPP_BL_BC_BPS
    } ,
    {   id:"17",
    label:"SPP_BL_L",
    Image:SPP_BL_L
    } ,
    {   id:"18",
    label:"SPP_BL_LPW_BP",
    Image:SPP_BL_LPW_BP
    } ,
    {   id:"19",
    label:"SPP_BL_LPW_BX",
    Image:SPP_BL_LPW_BX
    } ,
    {   id:"20",
    label:"SPP_BL_M_CH_PL",
    Image:SPP_BL_M_CH_PL
    } ,
    {   id:"21",
    label:"SPP_BL_S",
    Image:SPP_BL_S
    } ,
    {   id:"22",
    label:"SPP_BL_TROLLY",
    Image:SPP_BL_TROLLY
    } ,
    {   id:"23",
    label:"SPP_MINI_CH_PL",
    Image:SPP_MINI_CH_PL
    } ,
    {   id:"24",
    label:"SPP_MINI_SS",
    Image:SPP_MINI_SS
    }  ,
    {   id:"25",
    label:"SPP_2T4_18M",
    Image:SPP_2T4_18M
    } ,
    {   id:"26",
    label:"SPP_PR_BL_Ch_PAN",
    Image:SPP_PR_BL_Ch_PAN
    } ,
    {   id:"27",
    label:"SPP_PR_BL_SS_PAN",
    Image:SPP_PR_BL_SS_PAN
    }  ,
    {   id:"28",
    label:"SPP_SS",
    Image:SPP_SS
    } ,
    {   id:"29",
    label:"SPP_T_ECO_12M",
    Image:SPP_T_ECO_12M
    } ,
    {   id:"30",
    label:"SPP_T_BL_LPW_XP",
    Image:SPP_T_BL_LPW_XP
    } ,
    {   id:"31",
    label:"SSP_1_EXL_FR",
    Image:SSP_1_EXL_FR
    } ,
    {   id:"32",
    label:"SSP__EXL_P",
    Image:SSP__EXL_P
    } ,
    {   id:"33",
    label:"SSP__DLX_P",
    Image:SSP__DLX_P
    } ,
    {   id:"34",
    label:"SRP_SS",
    Image:SRP_SS
    } ,
    {   id:"35",
    label:"SRP_GR_P",
    Image:SRP_GR_P
    }  ,
    {   id:"36",
    label:"SRP_DLX_P",
    Image:SRP_DLX_P
    }  ,
    {   id:"37",
    label:"SRP_BL_S",
    Image:SRP_BL_S
    }  ,
    {   id:"38",
    label:"SRP_BL",
    Image:SRP_BL
    } ,
    {   id:"39",
    label:"SRC_BL_S",
    Image:SRC_BL_S
    }  ,
    {   id:"40",
    label:"SRC_BL_M",
    Image:SRC_BL_M
    }  ,
    {   id:"41",
    label:"SRC_BL",
    Image:SRC_BL
    } ,
    {   id:"42",
    label:"SSP_BL",
    Image:SSP_BL
    } ,
    {   id:"43",
    label:"SSP_BL_BC_TTW",
    Image:SSP_BL_BC_TTW
    }  ,
    {   id:"44",
    label:"SSP_BL_LPW_TT",
    Image:SSP_BL_LPW_TT
    }  ,
    {   id:"45",
    label:"SSP_DLX_FR",
    Image:SSP_DLX_FR
    } ,
    {   id:"46",
    label:"SSP_EXL_Baby",
    Image:SSP_EXL_Baby
    } ,
    {   id:"47",
    label:"SSP_FB",
    Image:SSP_FB
    } ,
    {   id:"48",
    label:"SSP_GR",
    Image:SSP_GR
    } ,
    {   id:"49",
    label:"SSP6BLJW",
    Image:SSP6BLJW
    }  ,
    {   id:"50",
    label:"TT71_SSP_5_TT721_SSP_10_TT722_SSP_20_TT723_SSP820",
    Image:TT71_SSP_5_TT721_SSP_10_TT722_SSP_20_TT723_SSP820
    }  ,
    {   id:"51",
    label:"TT723_SSP",
    Image:TT723_SSP
    } ,
    {   id:"52",
    label:"Weighbridge_Indicator",
    Image:Weighbridge_Indicator
    } , 
   
  ]
    

  
let arrImage=["12_Pans_GPP",
"CS_MS",
"CS_SS" ,
"Jw862_Jw863_Jw864" ,
"JW865_JW866_JW867_JW1190" ,
"Sansui Logo" ,
"Sansui" ,
"SBMI_UHW02" ,
"SMW_KL_MS" ,
"SPP_2T4 _1x2M" ,
"SPP_2T4_1.8M" ,
"SPP_6T4_2.0_M" ,
"SPP_300_BS" ,
"SPP_500_1_M" ,
"SPP_ABS" ,
"SPP_ABS_S" ,
"SPP_BENCH" ,
"SPP_BL_BC_BPS" ,
"SPP_BL_L" ,
"SPP_BL_LPW_BP" ,
"SPP_BL_LPW_BX" ,
"SPP_BL_M_CH_PL" ,
"SPP_BL_S" ,
"SPP_BL_TROLLY" ,
"SPP_MINI_CH.PL" ,
"SPP_MINI_SS" ,
"SPP_PR_BL_Ch.PAN (2)" ,
"SPP_PR_BL_Ch.PAN" ,
"SPP_PR_BL_SS_PAN" ,
"SPP_SS" ,
"SPP_T ECO_1.2M" ,
"SPP_T_BL_LPW_XP" ,
"SSP_1_EXL_FR" ,
"SSP__EXL_P" ,
"SSP__DLX_P" ,
"SRP_SS" ,
"SRP_GR_P" ,
"SRP_DLX_P" ,
"SRP_BL_S" ,
"SRP_BL" ,
"SRC_BL_S" ,
"SRC_BL_M" ,
"SRC_BL" ,
"SSP_BL" ,
"SSP_BL_BC_TTW" ,
"SSP_BL_LPW_TT" ,
"SSP_DLX_FR" ,
"SSP_EXL_(Baby)" ,
"SSP_FB" ,
"SSP_GR" ,
"SSP6BLJW_SSP10BLJW_SSP20BLJW_SSP30BLJW_SSP30 BLJW_SSP34BLJw_SSP3BLJW" ,
"TT71_SSP_5_TT721_SSP_10_TT722_SSP_20_TT723_SSP820" ,
"TT723_SSP 82" ,
"Weighbridge_Indicator"]


let ImportImg = arrImage.map((imgNm,indx)=>
        `import ${imgNm} from "../assets/${imgNm}.png" `
     )
