import { useContext, useState } from "react";
import { MachineSelectContext } from "../context/MachineSelect";
import ReactSelect from "react-select";


export const SelectMachine = ()=>{
  const {machineImage,addMachineToCart,isHidden,setHidden,
         machineValues,setMchValues,selectedOptionImg, setSelectImg}=useContext( MachineSelectContext ); 
  // const  [selectedOption, setSelect] = useState([]);
  
 


const changeHandlerValues=(e)=>{
  const {name,value} = e.target;
  setMchValues({...machineValues,[name]:value})

}

const selecthandleChange = (e)=>{          
  // console.log("e-",e.label);
  setSelectImg(e);
}



const saveValues= ()=>{  
        addMachineToCart({...machineValues,Image: selectedOptionImg.Image,ImgDetails:selectedOptionImg});
        setMchValues({   modelName:"",
          Capacity:"",
          Accuracy:"",
          Pan_Size:"",
          Quantity:"", 
          Price:""  
        });
        setSelectImg("")
    setHidden(false);

  }


const {
  modelName,
  Capacity,
  Accuracy,
  Pan_Size,
  Quantity, 
  Price} = machineValues;

  return(
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={isHidden}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="col-md-4">
                <label  className="form-label">State</label>
                </div>
              
                <input   value={modelName} name="modelName" 
                         onChange={changeHandlerValues} type="text" placeholder="Model Name"/>      
                <input   value={Capacity} name="Capacity" 
                         onChange={changeHandlerValues} type="text" placeholder="Capacity"/>
                <input   value={Accuracy} name="Accuracy"  
                          onChange={changeHandlerValues} type="text" placeholder="Accuracy"/>
                <input   value={Pan_Size} name="Pan_Size"  
                          onChange={changeHandlerValues} type="text" placeholder="Pan Size"/>
                <input   value={Quantity} name="Quantity"  
                          onChange={changeHandlerValues} type="number" placeholder="Quantity"/> 
                <input    value={Price} name="Price" 
                          onChange={changeHandlerValues} type="number" placeholder="Price"/>
              
              
              
              <ReactSelect
                   value={selectedOptionImg}
                    options={machineImage}
                  formatOptionLabel={machine => (
                                     <div className="country-option">
                                      <img src={machine.Image} alt="Weighing-Scale"  height="50px"/>
                                      <span>{machine.label}</span>
                                     </div> )}
                                  onChange={selecthandleChange}
                             />  


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button onClick={saveValues} type="button" data-bs-dismiss="modal" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>       
       
        )
}



export default SelectMachine;