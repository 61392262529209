import { useContext } from 'react';
import { MachineSelectContext } from '../../context/MachineSelect';
import "./MachineTable.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEdit } from '@fortawesome/free-regular-svg-icons';


const CheckoutItem = ({ cartItem }) => {
  const {modelName, Capacity, Accuracy, Price, Quantity, Image } = cartItem;
  const { editMachinedetails,
          removeMachineFromCart,
          clearMachineFromCart}=useContext( MachineSelectContext );


  const clearMachineHandler = () =>clearMachineFromCart(cartItem); 
  const editMachineHandler = () => editMachinedetails(cartItem); 
  const removeMachineHandler = () => removeMachineFromCart(cartItem); 


  return (
    <div className='checkoutitemcontainer'>
      <div className='image-container'>
        <img className="img-mchn"  src={Image} alt={`${Image}`} />
      </div>
      <span className='name'> {modelName} </span>
      <span className='name'> {Capacity} </span>
      <span className='name'> {Accuracy} </span>
      <span className='value'>{Quantity}</span>
      <span className='price'> {Price}</span>
      <div className='remove-button' onClick={clearMachineHandler}>
        &#10005;
      </div> 
      <div className='edit-btn' data-bs-toggle="modal" data-bs-target="#exampleModal" 
            onClick={editMachineHandler}>
         <FontAwesomeIcon icon={faEdit} />
     </div> 
    </div>


    //  <span className='quantity'> */}

    //     <div className='arrow' onClick={removeMachineHandler}>
    //       &#10094;
    //     </div> 

        
    //     <div className='arrow' onClick={addMachineHandler}>
    //       &#10095;
    //     </div> 
    //   </span>
     
  );
};

export default CheckoutItem;
