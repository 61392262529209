import './TableofMachine.css';





function TableofMachine({MachineItem}) {

return (
	<div className="table_Machine">
	<table >
		<tr>
		<th>Sr.No</th>
		<th>Model Name</th>
		<th>Capacity</th>
		<th>Pan Size</th>
		<th>Quantity</th>
		<th>Basic Price</th>
		<th>Model Picture</th>
		</tr>
		
		{MachineItem.map((val, key) => {
	
	const { modelName,Capacity, Accuracy, Prize, Quantity, Image } = val;

		return (
			<tr key={key}>
			<td>{key+1}.</td>
			<td>{modelName}</td>
			<td>{Capacity}</td>
			<td>{Accuracy}</td>
			<td>{Quantity}</td>
			<td>{Prize}</td>
			<td><img src={Image}  height="100vh"/></td>
			</tr>
		)
		})}
	</table>
	</div>
);
}

export default TableofMachine;
